import React, { useEffect } from "react";
import "../styles/Resources.css";

import BannerSection from "../components/BannerSection";
import BannerImage from "../assets/resources-banner.png";
import Resource1 from "../assets/resources1.png";
import Resource2 from "../assets/resources1.png";
import Resource3 from "../assets/resources1.png";
import Resource4 from "../assets/resources1.png";
import Resource5 from "../assets/resources1.png";
import Resource6 from "../assets/resources1.png";
import { Helmet } from "react-helmet";

const Resources = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const resources: any = [
    //  { image: Resource1, title: 'AI 時代行銷策略守則', link: '/#' },
    //  { image: Resource2, title: '2023 年節慶檔期 AI 行銷指南', link: '/#' },
    //  { image: Resource3, title: 'OMO：吸引 Z 世代消費者', link: '/#' },
    // { image: Resource4, title: '旅宿業行銷指南', link: '/#' },
    //  { image: Resource5, title: '全面開啟 2023 年旅客賽跑', link: '#' },
    //  { image: Resource6, title: '「可預測的行銷機制」秘笈', link: '#' },
  ];

  return (
    <div className="Resources">
      <Helmet>
        <title>下載免費白皮書及指南 - Big-O</title>
        <meta
          name="description"
          content="探索Big-O提供的各種白皮書和指南，了解最新的市場趨勢和創新解決方案。"
        />
        <meta
          name="keywords"
          content="Big-O, 免費白皮書, 下載指南, 電商白皮書, 科技趨勢白皮書"
        />
      </Helmet>
      <div>
        <BannerSection
          category="白皮書及指南"
          title="免費下載 Big-O 白皮書及指南"
          subtitle="結合科技趨勢及實際應用的寶典"
          backgroundImage={BannerImage}
          titleStyle={{}}
        />
      </div>
      <div className="main-resource-section py-4">
        <div className="container pt-4">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={Resource1} alt="Resource" className="img-fluid" />
            </div>
            <div className="col-md-6 mt-4">
              <h2>Big-O 電商白皮書致勝關鍵</h2>
              <p>如何運用AI小助手，打造具質感且便利的形象網站</p>
              <a href="#" className="btn custom-button-bottom">
                了解更多 →
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="other-resources-section py-5">
        <div className="container">
          <div className="row">
            {resources.map((resource: any, index: any) => (
              <div className="col-md-4 col-sm-6 mb-4" key={index}>
                <div className="border-line">
                  <a href={resource.link} className="resource-item-link">
                    <div className="resource-item card h-100">
                      <img
                        src={resource.image}
                        alt={resource.title}
                        className="card-img-top"
                      />
                      <div className="card-body">
                        <h5 className="card-title">{resource.title}</h5>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/*<div className="pagination-section text-center py-4">
        <nav>
          <ul className="pagination justify-content-center">
            <li className="page-item disabled">
              <a className="page-link" href="#">
                <i className="fas fa-chevron-left"></i>
              </a>
            </li>
            <li className="page-item"><a className="page-link" href="#">1</a></li>
            <li className="page-item"><a className="page-link" href="#">2</a></li>
            <li className="page-item"><a className="page-link" href="#">3</a></li>
            <li className="page-item"><a className="page-link" href="#">4</a></li>
            <li className="page-item"><a className="page-link" href="#">5</a></li>
            <li className="page-item"><a className="page-link" href="#">...</a></li>
            <li className="page-item"><a className="page-link" href="#">10</a></li>
            <li className="page-item">
              <a className="page-link" href="#">
                <i className="fas fa-chevron-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>*/}
    </div>
  );
};

export default Resources;
