import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/NewsHighlight.css';
import Pagination from '../components/Pagination';
import BannerSectionDark from '../components/BannerSectionDark';
import BannerImage from '../assets/NewsHighlights-banner.png'; 
import ArticleImage from '../assets/whychooseus/tcloud.png';

const NewsHighlight = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 10;
  const articles = [
    { 
      image: ArticleImage, 
      title: 'Big-O去年第四季獲遴選為-數位發展部「台灣雲市集平台」資服廠商', 
      description: '2024-01-05 台灣新創SaaS軟體服務公司 Big-O，於去年新設立後，隨即於去年底獲數位發展部「引領中小微型企業數位轉型戰略攻頂計畫」112年第六梯次之遴選優質資服廠商，可見資訊軟實力非常紮實，期待新的一年成長動能與規模持續，並穩健每一步規劃與設置，能滾動式調整各式客戶服務，創造更大的業績及服務綜效。Big-O表示，由於國內電商發展日益蓬勃，疫情期間電商不減反增，大品牌之電商規模之龐大，新創產業若需於該市場佔有一席之地，需要配合政府各方的數位轉型推動，並提供更優質更便利的工具，促進產業數位服務雲端化，也因台灣目前將打造成為AI科技島，勢必有更多的政府預算及規劃來推動數位轉型，並提供各式方案給更多不同零售產業。', 
      link: '/article-one' 
    },
   
  ];

  const totalPages = Math.ceil(articles.length / articlesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    
  };

  return (
    <div className="NewsHighlights">
      <Helmet>
        <title>新聞焦點 - Big-O 最新消息與發展 | Big-O SaaS 平台</title>
        <meta name="description" content="了解 Big-O 的最新動態和企業發展，包括我們如何幫助中小企業實現數位轉型。" />
        <meta name="keywords" content="Big-O, 新聞, SaaS, 數位轉型, 台灣雲市集, 資服廠商, 企業發展" />
      </Helmet>
      <div>
        <BannerSectionDark 
          category="新聞中心"
          title="展翅高飛焦點"
          subtitle="聚焦Big-O企業發展與最新資訊"
          backgroundImage={BannerImage}
          titleStyle={{}}
        />
      </div>
      <section className="articles-section">
        <div className="container">
          {articles.map((article, index) => (
            <div className="article-item d-flex pt-5 pb-4" key={index}>
              <div className="article-image">
                <img src={article.image} alt={article.title} className="img-fluid border p-3" />
              </div>
              <div className="article-content pl-4">
                <h3>{article.title}</h3>
                <p>{article.description}</p>
                <a href={article.link} className="btn btn-link">了解更多 →</a>
              </div>
            </div>
          ))}
        </div>
      </section>

      {articles.length > articlesPerPage && (
        <div className="pagination-section text-center py-4">
          <Pagination 
            currentPage={currentPage} 
            totalPages={totalPages} 
            onPageChange={handlePageChange} 
          />
        </div>
      )}
    </div>
  );
};

export default NewsHighlight;
