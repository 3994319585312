import React, { useEffect } from 'react';
import '../styles/MicroBusinessSolutions.css';

const MicroBusinessSolutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="micro-business-solutions py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="text-section">
              <h2>網路開店超簡單</h2>
              <p>立即開始你的網路生意</p>
              <p>
                您只需要一個平台，即可實現從設計、上架、支付、運輸、行銷、客服等一站式解決方案。SHOPLINE 提供完整的工具和支持，讓您專注於業務成長。
              </p>
              <a href="#start-now" className="btn btn-primary">
                立即免費試用
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="image-section">
              <img src="/path/to/your/image.png" alt="Micro Business Solutions" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MicroBusinessSolutions;
