import React from 'react';
import NewsDetail from '../components/NewsDetail';
import ArticleImage from '../assets/whychooseus/tcloud.png';

const ArticleOne = () => {
  const content = [
    "台灣新創SaaS軟體服務公司 Big-O，於去年新設立後，隨即於去年底獲數位發展部「引領中小微型企業數位轉型戰略攻頂計畫」112年第六梯次之遴選優質資服廠商，可見資訊軟實力非常紮實，期待新的一年成長動能與規模持續，並穩健每一步規劃與設置，能滾動式調整各式客戶服務，創造更大的業績及服務綜效。",
    "Big-O表示，由於國內電商發展日益蓬勃，疫情期間電商不減反增，大品牌之電商規模之龐大，新創產業若需於該市場佔有一席之地，需要配合政府各方的數位轉型推動，並提供更優質更便利的工具，促進產業數位服務雲端化。也因台灣目前將打造成為AI科技島，勢必有更多的政府預算及規劃來推動數位轉型，並提供各式方案給更多不同零售產業。",
    "隨著科技發展的趨勢，Big-O於這波浪潮下，能取得政府的支援與支持實屬不易，也因有了這項資歷，讓公司起步更為快速且更具影響力。",
    "迎接未來新的一年有更豐盛的收穫，系統更加優化，打造雲端數位服務最佳解。"
  ];

  return (
    <NewsDetail
      title="Big-O去年第四季獲遴選為-數位發展部「台灣雲市集平台」資服廠商"
      author="Big-O"
      date="2024-01-05"
      imageSrc={ArticleImage}
      imageAlt="Meta Certified Company Award"
      content={content}
    />
  );
};

export default ArticleOne;
