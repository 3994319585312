import React from 'react';
import { RouterProvider } from 'react-router-dom';
import ReactQueryProviders from "./contexts/ReactQueryProvider";
import './App.css';
//TODO: Should remove bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { router } from './routes';

function App() {
  

  return (
    <React.StrictMode>
      <ReactQueryProviders>
        <RouterProvider router={router} />
      </ReactQueryProviders>
    </React.StrictMode>
  );
}

export default App;
