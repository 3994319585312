import React, { useEffect } from "react";
import "../styles/Blog.css";
import BannerSectionDark from "../components/BannerSectionDark";
import BannerImage from "../assets/blog-banner.png";
import ResourceImage from "../assets/blog-1.png";
import { Helmet } from "react-helmet";
const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const resources = [
    {
      image: ResourceImage,
      title: "小公司也能取得低利率創業貸款",
      link: "/article-three",
    },
  ];

  return (
    <div className="Blog">
      <Helmet>
        <title>探索雲端數位智慧學院的創新創業洞察 - Big O</title>
        <meta
          name="description"
          content="深入我們雲端數位智慧學院的最新文章，發現數字創新和創業的前沿策略。"
        />
        <meta
          name="keywords"
          content="數位創新, 初創策略, 商業洞察, 雲技術洞察, 創業"
        />
      </Helmet>
      <div>
        <BannerSectionDark
          category="雲端數位智慧學院"
          title="雲端數位智慧學院"
          subtitle="與您愉快分享雲端數位創新創業新知"
          backgroundImage={BannerImage}
        />
      </div>
      <div className="resources-grid-section py-5">
        <div className="container">
          <div className="row">
            {resources.map((resource, index) => (
              <div className="col-md-4 col-sm-6 mb-4" key={index}>
                <a href={resource.link} className="resource-link">
                  <div className="resource-item card h-100">
                    <img
                      src={resource.image}
                      alt={resource.title}
                      className="card-img-top"
                    />
                    <div className="card-body">
                      <h5 className="card-title">{resource.title}</h5>
                    </div>
                    <div className="card-footer bg-white">
                      <span className="btn btn-link">了解更多 →</span>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
