import React from 'react';
import '../styles/PureBannerSection.css'; 

const PureBannerSection = ({ title, content, footer }) => {
  return (
    <div className="full-width-section">
      <h1 className="full-width-title">{title}</h1>
      <div className="full-width-content">
        {content}
      </div>
      {footer && <footer className="full-width-footer">{footer}</footer>}
    </div>
  );
};

export default PureBannerSection;
