import React, { useEffect } from "react";
import MainSlider from "../components/MainSlider";
import SecondMainSection from "../components/SecondMainSection";
import LeftSliderSection from "../components/LeftSliderSection";
import BrandServices from "../components/BrandServices";
import LatestCasesT1 from "../components/LatestCasesT1";
import BrandPartners from "../components/BrandPartners";
import CallToAction from "../components/CallToAction";
import { Helmet } from "react-helmet";
import "../styles/style.css";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Big-O - 數位轉型，與你同行</title>
        <meta
          name="description"
          content="立即開始實現數位轉型之旅！與 Big-O 合作創造未來！"
        />
        <meta
          name="keywords"
          content="Big-O, 數位轉型, 台灣中小企業, 電子商務, 形象網站, 雲端解決方案, AI技術應用"
        />
      </Helmet>
      <MainSlider />
      <SecondMainSection />
      <LeftSliderSection />
      <BrandServices />
      <LatestCasesT1 />
      <BrandPartners />
      <CallToAction />
    </div>
  );
};

export default Home;
