import { NavLink, Outlet } from "react-router-dom";
import { useGetMerchantByStorefrontDomainQuery } from "../hooks/Merchant";
import { useEffect } from "react";
import { Nav } from "react-bootstrap";

export function MemberLayout() {
  const { data, isFetched } = useGetMerchantByStorefrontDomainQuery(
    process.env.REACT_APP_STOREFRONT_DOMAIN!
  );

  return (
    <section className="py-5">
      <div className="container">
        <Nav variant="tabs">
          <Nav.Item>
            <NavLink
              to="dashboard"
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link text-muted"
              }
            >
              個人資訊
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink
              to="order"
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link text-muted"
              }
            >
              訂單
            </NavLink>
          </Nav.Item>
        </Nav>
        <div className="content-big-wrap p-3">
          <Outlet />
        </div>
      </div>
    </section>
  );
}
