// CartDropdown.tsx
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaTrash } from "react-icons/fa";
import { Button } from "react-bootstrap";

interface CartItem {
  name: string;
  price: number;
  count: number;
  imageUrl: string;
}

interface CartDropdownProps {
  items: CartItem[];
  setCartItems: React.Dispatch<React.SetStateAction<CartItem[]>>;
}

const CartDropdown: React.FC<CartDropdownProps> = ({ items, setCartItems }) => {
  return (
    <div>
      <div style={{ marginBottom: 5 }}>
        <span>購物車</span>
      </div>
      {/* empty cart */}
      {items.length === 0 && (
        <div style={{ textAlign: "center", padding: 15, color: "gray" }}>
          購物車是空的
        </div>
      )}
      {/* items */}
      {items.map((item, index) => (
        <div
          key={index}
          className="pt-2"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", minHeight: 80 }}>
            <img
              src={item.imageUrl}
              alt={item.name}
              style={{
                maxWidth: 100,
                maxHeight: 80,
                width: "auto",
                height: "100%",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 10,
                justifyContent: "space-around",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                height: 72,
              }}
            >
              <span>{item.name}</span>
              <span style={{ fontWeight: 400, fontSize: 14, marginTop: 5 }}>
                {item.count} * NT${item.price.toLocaleString()}
              </span>
            </div>
          </div>
          <div>
            <FaTrash
              style={{ width: 15, height: 15, cursor: "pointer" }}
              onClick={() => {
                const newItems = items.filter((_, i) => i !== index);
                setCartItems(newItems);
              }}
            />
          </div>
        </div>
      ))}
      {/* checkout button */}
      <div
        style={{
          cursor: "pointer",
          marginTop: 15,
          padding: 15,
          backgroundColor: "#333",
          textAlign: "center",
          color: "#fff",
        }}
        onClick={() => {
          window.location.href = "/cart";
        }}
      >
        購物車
      </div>
    </div>
  );
};

export default CartDropdown;
