import React from 'react';
import '../styles/SecondMainSection.css'; 
import ThreeCircle from "../assets/threecircle.png";

const SecondMainSection  = () => {
  return (
    <section className="SecondMainSection py-5">
      <div className='container'>
     
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 ">
            <div className="text-section text-lg-start text-center">
              <h2>Create Lasting Memories</h2>
              <div className='main-title'>Cloud × AI × Micro Commerce</div>
              <p>三軸驅動新創成長與發展<br></br>完成AIｘIA=AI²</p>
            </div>
          </div>
          <div className="col-12 col-lg-6 ">
            <div className="diagram-section position-relative  text-lg-start text-center">
             
              <img src={ThreeCircle} alt="ThreeCircle" className="img-fluid  w-75"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecondMainSection ;
