import React, { useEffect } from 'react';
import '../styles/PrivacyPolicy.css'; 
import PureBannerSection from '../components/PureBannerSection';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = (
    <>
    <section>
        <p>關於個人資料保護，請詳細閱讀以下 Big-O 的隱私權聲明：
           Big-O 為大鷗摩飛智能有限公司所經營之品牌；為了維護個人隱私權與支持個人資料保護，Big-O 謹以下列聲明，向您說明 Big-O 蒐集個人資料之目的、類別、利用範圍及方式、以及您所得行使之權利等事項； 如果您對於 Big-O 的隱私權聲明、以下相關告知事項、或與個人資料保護有關之相關事項有任何疑問，可以和 Big-O 客服中心聯絡，Big-O 將儘快回覆說明。</p>
    </section>
      <section>
        <h4>適用範圍</h4>
        <p>Big-O 隱私權聲明及其所包含之告知事項，僅適用於 Big-O 所擁有及經營的網站。Big-O 網站內可能包含許多連結、或其他合作夥伴及個別開店店家所提供的商品或服務，關於該等連結網站、合作夥伴網站或個別開店店家之網路店家的隱私權聲明及與個人資料保護有關之告知事項，請參閱各該連結網站、合作夥伴網站或個別開店店家之網路店家。</p>
      </section>
      <section>
        <h4>個人資料蒐集之目的與類別</h4>
        <p>Big-O 為了提供電子商務服務、售後服務、履行法定或合約義務、保護當事人及相關利害關係人之權益、行銷、客戶管理與服務、以及經營合於營業登記項目或組織章程所定之業務等目的，依照各服務之性質，有可能會蒐集您的姓名、聯絡方式(包括但不限於電話、E-MAIL及地址等)、為完成收款或付款所需之資料、IP位址、及其他得以直接或間接識別使用者身份之個人資料。此外，為提升服務品質，Big-O 會依照所提供服務之性質，記錄使用者的IP位址、以及在 Big-O 相關網站或 APP 內的瀏覽活動(例如：使用者所使用的軟硬體、所點選的網頁、下載的 APP)等資料，但是這些資料僅供作流量分析和網路行為調查，以便於改善 Big-O 相關網站的服務品質，不會和特定個人相聯繫。</p>
      </section>
      <section>
        <h4>個人資料的利用</h4>
        <p>Big-O 所蒐集的足以識別使用者身份的個人資料，都僅供 Big-O 於其內部、依照蒐集之目的進行處理和利用，除非事先說明、或為完成提供服務或履行合約義務之必要、或依照相關法令規定或有權主管機關之命令或要求，否則 Big-O 不會將足以識別使用者身份的個人資料提供給第三人(包括境內及境外)、或移作蒐集目的以外之使用。在法令所定應保存之期間內，Big-O 會持續保管、處理及利用相關資料。</p>
      </section>
      <section>
        <h4>資料安全</h4>
        <p>Big-O 將以合於產業標準之合理技術及程序，維護個人資料之安全。</p>
      </section>
      <section>
        <h4>資料當事人之權利</h4>
        <p>資料當事人可以請求查詢、閱覽本人的個人資料或要求給予複本。若您的個人資料有變更、或發現您的個人資料不正確，可以向 Big-O 要求修改或更正。當蒐集個人資料之目的消失或期限屆滿時，您可要求刪除、停止處理或利用個人資料。但因 Big-O 行職務或業務所必須者，不在此限。</p>
      </section>
      <section>
        <h4>Cookie</h4>
        <p>為了便利使用者，Big-O 網站可能會讀取儲存在使用者電腦中的cookie資料。使用者可以經由瀏覽器的設定，取消、或限制此項功能，但可能因此無法使用部分網站功能。若您想知道如何取消、或限制此項功能，請與 Big-O 客服中心聯絡。</p>
      </section>
      <section>
        <h4>影響</h4>
        <p>若您不願意提供各服務或交易所要求的相關個人資料予 Big-O，並同意 Big-O 就該等個人資料依法令規定、以及本隱私權聲明及其相關告知內容為相關之個人資料蒐集、處理、利用及國際傳輸，Big-O 將尊重您的決定，但依照各該服務之性質或條件，您可能因此無法使用該等服務或完成相關交易，Big-O 並保留是否同意提供該等相關服務或完成相關交易之權利。</p>
      </section>
      <section>
        <h4>修訂之權利</h4>
        <p>Big-O 有權隨時修訂本隱私權聲明及相關告知事項，並得於修訂後公布在 Big-O 網站，不另行個別通知，您可以隨時在 Big-O 網站上詳閱修訂後的隱私權聲明及相關告知事項。</p>
      </section>
    </>
  );

  const footer = (
    <>
      <p>最後更新日期：2024-08-08</p>
      <p>© 2024 Big-O Information Intelligence Corp.</p>
    </>
  );

  return (
    <div>
      <PureBannerSection
        title="隱私權聲明Privacy Policy"
      />
      <div className="privacy-policy-container">
        <div className="privacy-policy-content">
            {content}
        </div>
        <footer className="privacy-policy-footer">
            {footer}
        </footer>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
