import React from 'react';
import ECPayLogo from '../assets/ecpay.png';
import GemstonePlanetLogo from '../assets/gemstone-planet.png'; 
import DefanOriginalLogo from '../assets/defan-original.png'; 

const BrandPartners = ({ title = "品牌策略聯盟夥伴" }) => {
  const partners = [
    { logo: ECPayLogo, alt: 'ECPay' },
    { logo: GemstonePlanetLogo, alt: 'Gemstone Planet' },
    { logo: DefanOriginalLogo, alt: 'Defan Original' },
  ];

  return (
    <section className="BrandPartners py-5">
      <div className="container">
        <h2 className="text-center">{title}</h2>
        <div className="row justify-content-center align-items-center">
          {partners.map((partner, index) => (
            <div className="col-12 col-lg-4 text-center mb-4" key={index}>
              <img src={partner.logo} alt={partner.alt} className="img-fluid partner-logo w-50 p-3" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BrandPartners;
