import React from 'react';
import '../styles/NewsDetail.css'; 
  const NewsDetail = ({ title, author, date, imageSrc, imageAlt, content }) => {
  const parseContent = (text) => {
    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  };

  return (
    <div className="news-detail my-5">
      <div className="container">
        <header className="post-header">
          <h1>{title}</h1>
          <div className="post-meta">
            <span className="author">By {author}</span>
            <span className="date">| {date}</span>
          </div>
        </header>

        <div className="post-image">
          <img src={imageSrc} alt={imageAlt} className="img-fluid border p-4" />
        </div>

        <div className="post-content">
          {content.map((item, index) => {
            if (Array.isArray(item)) {
              return (
                <ul key={index}>
                  {item.map((listItem, listIndex) => (
                    <li key={listIndex}>{parseContent(listItem)}</li>
                  ))}
                </ul>
              );
            } else {
              return <p key={index}>{parseContent(item)}</p>;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
