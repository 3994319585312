import React, { useEffect, useState } from "react";
import PureBannerSection from "../components/PureBannerSection";
import { Form, Button, InputGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import BannerImage from "../assets/password.jpg";
import BannerSection from "../components/BannerSection";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // Extract the resetPasswordToken from the query string to reset the password
  const [resetPasswordToken, setResetPasswordToken] = useState("");

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    // Extract the resetPasswordToken from the query string
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("resetPasswordToken");
    if (token) {
      setResetPasswordToken(token);
    }
  }, [location]);

  const handleResetPassword = () => {
    alert(`重設密碼, token: ${resetPasswordToken}`);
  };

  return (
    <>
      <BannerSection
        category=""
        title="重設密碼"
        subtitle=""
        backgroundImage={BannerImage}
        titleStyle={{ textAlign: "center", width: "100%" }}
      />
      <section className="py-5">
        <div className="container">
          <Form>
            <h2>重設密碼</h2>
            <p>請輸入您的新密碼</p>

            <Form.Group className="mb-3" controlId="password">
              <Form.Label>新密碼</Form.Label>

              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="請輸入新密碼"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputGroup.Text
                  onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}{" "}
                  {/* Toggle icons */}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Button
              variant="primary"
              disabled={!password}
              onClick={handleResetPassword}
            >
              送出
            </Button>
          </Form>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
