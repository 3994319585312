import axios from "axios";

export interface Merchant {
  id: number;
  name: string;
  storefront_domain: string;
  createdAt: string;
  updatedAt: string;
  globalSetting: {
    id: number;
    title: string;
    description: string;
    keywords: string;
    facebookFanPageId: string;
    googleAnalyticsTrackingId: string;
    lineLink: string;
    tawkToWidgetCode: string;
    merchantId: number;
    createdAt: string;
    updatedAt: string;
  };
}

export const getMerchantByStorefrontDomain = async (
  storefrontDomain: string
) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const response = await axios.get<Merchant>(
    `${API_URL}/merchants/storefront_domain/${storefrontDomain}`
  );
  return response.data;
};
