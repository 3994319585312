import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import { useGetMerchantByStorefrontDomainQuery } from "../hooks/Merchant";
import { useEffect } from "react";

export function MainLayout() {
  const { data, isFetched } = useGetMerchantByStorefrontDomainQuery(
    process.env.REACT_APP_STOREFRONT_DOMAIN!
  );

  useEffect(() => {
    if (data?.globalSetting.tawkToWidgetCode) {
      const scriptContent = data.globalSetting.tawkToWidgetCode;

      // Regular expression to match the `src` attribute inside the script tag
      const scriptMatch = scriptContent.match(/src=['"]([^'"]+)['"]/);

      if (scriptMatch && scriptMatch[1]) {
        const scriptSrc = scriptMatch[1];

        // Dynamically create the script element
        const tawkScript = document.createElement("script");
        tawkScript.src = scriptSrc;
        tawkScript.async = true;
        tawkScript.charset = "UTF-8";
        tawkScript.setAttribute("crossorigin", "*");

        // Append the script to the body (or a specific container)
        document.body.appendChild(tawkScript);

        // Cleanup: remove the script when the component unmounts
        return () => {
          document.body.removeChild(tawkScript);
        };
      }
    }
  }, [data]);

  return (
    <div className="App">
      <Header />
      <div className="content-big-wrap">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
