import { Form } from "react-bootstrap";
import BannerSection from "../components/BannerSection";
import { useEffect, useState } from "react";
import bannerImage from "../assets/Checkout-banner2.jpg";

interface CartItem {
  name: string;
  price: number;
  count: number;
  imageUrl: string;
}

const Checkout = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [username, setUsername] = useState("王小明");
  const [phone, setPhone] = useState("0912345678");
  const [email, setEmail] = useState("email@gmail.com");
  const [items, setItems] = useState<CartItem[]>([
    {
      name: "自選版型-starlight",
      price: 5600,
      count: 1,
      imageUrl:
        "https://big-o-prod.s3.ap-northeast-1.amazonaws.com/merchant/1/media/4",
    },
    {
      name: "專屬獨有網域",
      price: 1050,
      count: 1,
      imageUrl:
        "https://big-o-prod.s3.ap-northeast-1.amazonaws.com/merchant/1/media/5",
    },
  ]);
  const [appliedVoucher, setAppliedVoucher] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getTotalPrice = () => {
    return items.reduce((acc, item) => acc + item.price * item.count, 0);
  };

  const getTotalPriceAfterDiscount = () => {
    if (appliedVoucher) {
      return getTotalPrice() * 0.9;
    }
    return getTotalPrice();
  };

  return (
    <>
      <BannerSection
        category=""
        title="訂單結帳"
        subtitle=""
        backgroundImage={bannerImage}
        titleStyle={{ textAlign: "center", width: "100%" }}
      />
      <section className="py-5">
        <div className="container d-md-flex">
          {/* product list */}
          <div className="flex-grow-1">
            <h4
              style={{
                width: "fit-content",
                paddingRight: 10,
                position: "relative",
                fontWeight: 400,
                zIndex: 2,
              }}
            >
              商品清單
            </h4>
            {items.map((item, index) => (
              <div
                key={index}
                className="d-flex align-items-center justify-content-between"
                style={{
                  marginTop: 15,
                  padding: 15,
                  backgroundColor: "#f7f5fa",
                  borderRadius: 5,
                }}
              >
                <div className="d-flex align-items-center" style={{ flex: 2 }}>
                  <img
                    className="d-none d-md-block"
                    src={item.imageUrl}
                    alt="product"
                    style={{
                      width: 100,
                      height: 100,
                      objectFit: "cover",
                      borderRadius: 5,
                    }}
                  />
                  <div className="ms-3">
                    <h5 style={{ fontWeight: 400, fontSize: 16 }}>
                      {item.name}
                    </h5>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      NT$ {item.price.toLocaleString()}
                    </span>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <span style={{ marginLeft: 5, marginRight: 5 }}>
                    {item.count}
                  </span>
                </div>
                <div style={{ flex: 1 }}>
                  <span style={{ marginLeft: 15 }}>
                    NT$ {(item.price * item.count).toLocaleString()}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="pb-5">
        <div className="container d-md-flex">
          {/* contact info */}
          <div className="flex-grow-1">
            <h4
              style={{
                width: "fit-content",
                paddingRight: 10,
                position: "relative",
                fontWeight: 400,
                zIndex: 2,
              }}
            >
              聯絡資訊
            </h4>
            {/* username */}
            <Form>
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>姓名</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="姓名"
                  value={username}
                  disabled
                />
              </Form.Group>

              {/* email */}
              <div className="d-flex">
                <Form.Group className="mb-3 flex-grow-1 me-2" controlId="email">
                  <Form.Label>電子郵件</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="電子郵件"
                    value={email}
                    disabled
                  />
                </Form.Group>

                {/* phone */}
                <Form.Group className="mb-3 flex-grow-1" controlId="phone">
                  <Form.Label>手機</Form.Label>
                  <div className="input-group">
                    <span className="input-group-text">+886</span>
                    <Form.Control
                      type="text"
                      name="contactPhone"
                      value={phone}
                      disabled
                    />
                  </div>
                </Form.Group>
              </div>

              {/* select payment method */}
              <Form.Group className="mb-3" controlId="paymentMethod">
                <Form.Label>付款方式</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>PAYUNi 統一支付</option>
                </Form.Select>
              </Form.Group>

              {/* note */}
              <Form.Group className="mb-3" controlId="note">
                <Form.Label>備註</Form.Label>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>
            </Form>
          </div>
          {/* order detail */}
          <div
            className="p-3 mx-md-3"
            style={{
              backgroundColor: "#f7f5fa",
              height: "fit-content",
              width: isMobile ? "100%" : 300,
            }}
          >
            <div style={{ position: "relative" }}>
              <h4
                style={{
                  width: "fit-content",
                  paddingRight: 10,
                  position: "relative",
                  fontWeight: 400,
                  zIndex: 2,
                  backgroundColor: "#f7f5fa",
                }}
              >
                您的訂單
              </h4>
              {/* decorate */}
              <span
                style={{
                  position: "absolute",
                  left: 0,
                  top: 18,
                  height: 1,
                  width: "100%",
                  backgroundColor: "#e1e1e1",
                  zIndex: 1,
                }}
              ></span>
            </div>
            {/* entre voucher */}
            <div>
              <span style={{ fontWeight: 300 }}>
                輸入優惠券、推薦代碼，結帳時將自動折抵
              </span>
              <div className="d-flex mt-2">
                <input
                  type="text"
                  placeholder="輸入代碼"
                  className="flex-grow-1"
                  style={{
                    width: "70%",
                    borderRadius: 0,
                    border: "1px solid #a7a7a7",
                    borderRight: "none",
                    padding: "10px",
                    fontWeight: 300,
                  }}
                />
                <button
                  style={{
                    backgroundColor: "#333",
                    color: "#fff",
                    border: "none",
                    padding: "10px 20px",
                  }}
                  onClick={() => {
                    setAppliedVoucher("VIP 促銷9折");
                  }}
                >
                  套用
                </button>
              </div>
            </div>

            {/* product list */}
            <div className="mt-4">
              <div className="d-flex justify-content-between">
                <h5 style={{ fontWeight: 400 }}>商品</h5>
              </div>
              {items.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <span style={{ fontWeight: 400, fontSize: 14 }}>
                    {item.name}
                  </span>
                  <span style={{ fontWeight: 400, fontSize: 14 }}>
                    {item.count} * NT$ {item.price.toLocaleString()}
                  </span>
                </div>
              ))}
            </div>
            <hr style={{ opacity: 1, borderColor: "#e1e1e1" }} />
            <div className="d-flex justify-content-between">
              <h5 style={{ fontWeight: 400 }}>商品小計</h5>
              <span>NT$ {getTotalPrice().toLocaleString()}</span>
            </div>
            {appliedVoucher && (
              <div className="d-flex justify-content-between">
                <h5 style={{ fontWeight: 400 }}>折扣</h5>
                <span>{appliedVoucher}</span>
              </div>
            )}
            <div className="d-flex justify-content-between">
              <h5 style={{ fontWeight: 400 }}>總計</h5>
              <span style={{ color: "#f45d96" }}>
                NT$ {getTotalPriceAfterDiscount().toLocaleString()}
              </span>
            </div>
            <div
              style={{
                cursor: "pointer",
                marginTop: 15,
                padding: 15,
                backgroundColor: "#333",
                textAlign: "center",
                color: "#fff",
              }}
              onClick={() => {
                window.location.href = "/order-complete";
              }}
            >
              結帳
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Checkout;
