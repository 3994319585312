import React from 'react';
import '../styles/BrandServices.css'; 

import Icon1 from '../assets/BusinessConversation.svg'; 
import Icon2 from '../assets/BusinessGrowth.svg'; 
import Icon3 from '../assets/BusinessHierarchy.svg'; 
import Icon4 from '../assets/Configure.svg'; 
import Icon5 from '../assets/Idea.svg'; 

const BrandServices = () => {
  const services = [
    { icon: Icon1, title: '新創成長顧問' },
    { icon: Icon2, title: '系統整合服務' },
    { icon: Icon3, title: 'AI數據資料預測服務' },
    { icon: Icon4, title: 'CRM、碳排計算服務' },
    { icon: Icon5, title: '數位轉型策略規劃與執行' },
  ];

  return (
    <section className="BrandServices py-5">
      <div>
        <h2 className="text-center mb-5">品牌五大服務</h2>
        <div className="row justify-content-center text-center">
          {services.map((service, index) => (
            <div className="col-md-2 col-sm-4 col-6 mb-4" key={index}>
              <div className="service-item">
                <img src={service.icon} alt={service.title} className="img-fluid mb-3" />
                <p>{service.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BrandServices;
