import React, { useEffect, useState } from "react";
import "../styles/ThemeDesign.css";
import "../styles/style.css";
import BannerSection from "../components/BannerSection";
import ThemeDesignBanner from "../assets/ThemeDesignBanner.png";
import TemplateImg from "../assets/TemplateImg.png";
import { useFindProductsByCategoryIdAndPaymentTypeQuery } from "../hooks/Product";
import { Helmet } from "react-helmet";

const ThemeDesign = () => {
  const [showModal, setShowModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const { data: templateProducts, isFetched: isTemplateProductsFetched } =
    useFindProductsByCategoryIdAndPaymentTypeQuery(
      +process.env.REACT_APP_API_TEMPLATE_CATEGORY_ID!, // the mainCategoryId for '套版'
      "BUYOUT"
    );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePreviewClick = (previewUrl: string) => {
    setPreviewUrl(previewUrl);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const handleAddToCart = (product: any) => {
    console.log("Add to cart:", product);
  };

  return (
    <section className="theme-design">
      <Helmet>
        <title>選擇專業的網站主題設計 - Big-O</title>
        <meta
          name="description"
          content="探索Big-O的豐富版型選擇，選擇專業且具質感的網站主題，提升您的網路形象。"
        />
        <meta
          name="keywords"
          content="網站版型, 自選主題, 專業網站設計, Big-O, 主題預覽"
        />
      </Helmet>
      <div>
        <BannerSection
          category="自選版型"
          title="自由選擇具質感且專業的版面，讓您的網路門面更具吸引力"
          subtitle='立即點選預覽屬於您的專屬風格！請<a href="/contact">聯繫我們</a>'
          backgroundImage={ThemeDesignBanner}
          titleStyle={{}}
        />
      </div>
      {templateProducts && templateProducts.length > 0 && (
        <div className="bg-gray">
          <div className="container">
            <div className="theme-preview py-5">
              <div className="row align-items-center">
                <div className="col-md-6 col-sm-6 text-center">
                  <img
                    src={templateProducts[0].productMedias[0].media.url}
                    alt={`${templateProducts[0].name} Theme`}
                    className="img-fluid mb-3 w-50"
                  />
                </div>
                <div className="col-md-6">
                  <h3 className="theme-name">
                    <span className="badge new">NEW</span>
                    <br></br>
                    {templateProducts[0].name}
                  </h3>
                  <p
                    className="theme-description"
                    dangerouslySetInnerHTML={{
                      __html: templateProducts[0].description,
                    }}
                  ></p>
                  <div className="d-flex">
                    <div>
                      <span className="currency">NT$ </span>
                      <span className="price">{templateProducts[0].price}</span>
                    </div>
                    <span
                      className="card-original-price"
                      style={{ marginLeft: 10 }}
                    >
                      NT$ <span>{templateProducts[0].originalPrice}</span>
                    </span>
                  </div>
                  <a
                    type="button"
                    className="btn custom-button-bottom"
                    onClick={() =>
                      handlePreviewClick(templateProducts[0].slogan)
                    }
                  >
                    立即預覽 →
                  </a>
                  <a
                    type="button"
                    className="btn custom-button-bottom-outline"
                    style={{ marginLeft: 5 }}
                    onClick={() => handleAddToCart(templateProducts[0])}
                  >
                    加入購物車
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="row theme-gallery mt-5">
          {templateProducts &&
            templateProducts.map((product, index) => (
              <div className="col-lg-4 col-md-6 mb-4" key={index}>
                <a onClick={() => handlePreviewClick(product.slogan)}>
                  <div className="theme-item">
                    <img
                      src={product.productMedias[0]?.media?.url}
                      alt={`${product.name} Theme`}
                      className="img-fluid w-50"
                    />
                    <h4 className="theme-name">{product.name}</h4>
                  </div>
                </a>
                <div className="d-flex justify-content-center">
                  <div>
                    <span className="currency" style={{ fontSize: 18 }}>
                      NT${" "}
                    </span>
                    <span className="price" style={{ fontSize: 24 }}>
                      {product.price}
                    </span>
                  </div>
                  <span
                    className="card-original-price"
                    style={{ marginLeft: 10, marginTop: 2, fontSize: 18 }}
                  >
                    NT${" "}
                    <span style={{ fontSize: 18 }}>
                      {product.originalPrice}
                    </span>
                  </span>
                </div>
                <p
                  className="theme-description"
                  dangerouslySetInnerHTML={{ __html: product.description }}
                ></p>
                <div className="text-center">
                  <a
                    type="button"
                    className="btn custom-button-bottom"
                    style={{ fontSize: 12, padding: 12 }}
                    onClick={() => handlePreviewClick(product.slogan)}
                  >
                    立即預覽 →
                  </a>
                  {/* <a type="button" className="btn custom-button-bottom-outline" style={{ marginLeft: 5, fontSize: 12, padding: 12 }} onClick={() => handleAddToCart(product)}>加入購物車</a> */}
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* Modal */}
      {showModal && (
        <div
          className="modal fade show d-block"
          tabIndex={-1}
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">預覽主題</h5>
                <a onClick={handleClose}>
                  <span style={{ fontSize: "20px" }}>&times;</span>
                </a>
              </div>
              <div className="modal-body">
                <iframe
                  src={previewUrl}
                  width="100%"
                  height="600px"
                  title="Theme Preview"
                  style={{ border: 0 }}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  關閉
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ThemeDesign;
