import React, { useEffect, useState } from "react";
import PureBannerSection from "../components/PureBannerSection";
import { Form, Button, InputGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import BannerSection from "../components/BannerSection";
import BannerImage from "../assets/password.jpg";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleForgotPassword = () => {
    alert(`寄送重設密碼信件`);
  };

  return (
    <>
      <BannerSection
        category=""
        title="忘記密碼？"
        subtitle=""
        backgroundImage={BannerImage}
        titleStyle={{ textAlign: "center", width: "100%" }}
      />
      <section className="py-5">
        <div className="container">
          <Form>
            <h2>重設密碼</h2>
            <p>請輸入您的信箱，我們將寄出重設密碼信件給您</p>

            <Form.Group className="mb-3" controlId="email">
              <Form.Label>電子信箱</Form.Label>
              <Form.Control
                type="email"
                placeholder="請輸入電子信箱"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Button
              variant="primary"
              disabled={!email}
              onClick={handleForgotPassword}
            >
              重設密碼
            </Button>
          </Form>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
