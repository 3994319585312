import React from 'react';
import NewsDetail from '../components/NewsDetail';
import ArticleImage from '../assets/blog-1.png';

const ArticleThree = () => {
  const content = [
    "資金是企業經營最重要的一環，更重要的是如何在小額資本的限制下，能夠善用政府提供的創業資源，並申請政府所提供的專案創業貸款，具備低優惠利率，有的還有利息補貼。",
    "然而，公司初期可能營收不穩，在銀行的角度裡，看的都是「未來營運穩定性」、「是否持續穩健獲得營收」作為初審判斷的基礎標準。我們應該如何讓自己的信用維持在一定的水平，這相對重要。",
    "現在政府提供有三種創業貸款：地方政府創業貸款、中央青年創業啟動金貸款、勞動部微型鳳凰創業貸款。",
    "地方政府創業貸款：這個部分著重在「地方政府」，通常每個縣市都有自己出的一套輔導創新創業的貸款服務，這邊簡單舉例說明一下：台北市政府創業貸款。<a href='https://easyloan.taipei'>點擊前往連結</a>",
    "通常自己要學習申請創業貸款，這個第一個看的就是公司設立「所在地」，這看的就是你公司設立是否座落在台北市，必須要注意的是「年齡為成年以上，45歲以下並且設籍本市一年以上之中華民國國民」。",
    "第二則是要注意的點，必須在公司設立後貸款申請前，「三年內」取得政府的創業輔導課程時數達二十小時以上。這個可以上網去上線上課程。很方便也很容易完成。",
    "政府有提供簡易貸款，也就是快速可以取得資金，通常過去經驗，就是一個月的起訖。你要能自我估算這個資金取得的時間點，並且要具備有能力可以攤還。",
    "台北市政府的方案有個好處，就是過件後的利息是有補貼的。而且是貸款期間全額補貼。",
    "每個縣市方案都不太一樣，應該要好好的了解自己的需求，找到最適合的解決方案。",
    "中央青年創業及啟動金貸款：這個顧名思義就是中央政府讓基本上的「八大行庫」都能用自有資金提供給欲創業的年輕人，這裡特別了一點，就是分別有個人名義借貸或是用企業名義借貸。<a href='https://www.sme.gov.tw/article-tw-2570-4238'>點擊前往連結</a> ",
    "所以不建議一開始公司設立就去借款，而應該是大概有半年左右來看看自己的營運狀況來評估。",
    "這個當然主要對象就是勞動部，勞動部針對年輕女性45歲以下，或是中高齡失業後要創業的貸款，資格審查條件時程攏長，會有顧問到現場訪視，並討論你所撰寫的計畫書。勞動部微型鳳凰創業貸款：<a href='https://beboss.wda.gov.tw/cp.aspx?n=28'>點擊前往連結</a> ",
    "要特別注意一件事，外面坊間很多代辦公司或是教學課程教授你如何寫計畫書，基本上政府單位是都會告知沒有委託任何一家代辦公司及任何一家授課公司，自己的計畫書真的要自己寫。因為在你申請的過程，被顧問洽詢時，大家都聽得到，也聽得出來是不是真的有在對自己的公司做規劃。",
    "我個人都是自身經驗，傳授自我心法，有需要更進一步的了解，未來可以開一門課程，實際分享這段申請旅程，或是需要立即諮詢也可以到<a href='/contact'>聯絡我們</a>"
  ];

  return (
    <NewsDetail
      title="小公司也能取得低利率創業貸款！Big-O創辦人傳授心法"
      author="Elaine Chang"
      date="2024-08-27"
      imageSrc={ArticleImage}
      imageAlt="Meta Certified Company Award"
      content={content}
    />
  );
};

export default ArticleThree;
