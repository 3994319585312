// invoke api to create a contact request
import axios from 'axios';

export interface Media {
    id: number;
    url: string;
    name: string;
    createdAt: string;
    updatedAt: string;
}

export interface ProductMedia {
    media: Media;
    mediaId: number;
    productId: number;
    createdAt: string;
    updatedAt: string;
}

// payment type
export type PaymentType = 'SUBSCRIPTION_MONTHLY' | 'SUBSCRIPTION_ANNUALLY' | 'BUYOUT';

export interface Product {
    id: number;
    paymentType: PaymentType
    name: string;
    price: number;
    originalPrice: number;
    description: string;
    slogan: string;
    mainCategoryId: number;
    subCategoryId: number;
    merchantId: number;
    createdAt: string;
    updatedAt: string;
    productMedias: ProductMedia[];
}

export const findProductsByCategoryIdAndPaymentType = async (mainCategoryId: number, paymentType: PaymentType) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const MERCHANT_ID = parseInt(process.env.REACT_APP_API_MERCHANT_ID!);
    const response = await axios.get<Product[]>(`${API_URL}/products/merchant/${MERCHANT_ID}/mainCategory/${mainCategoryId}/paymentType/${paymentType}`);
    return response.data;
};