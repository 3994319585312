import { Button, Card, Table } from "react-bootstrap";
import { useGetMerchantByStorefrontDomainQuery } from "../hooks/Merchant";
import { useEffect } from "react";

export function MemberOrder() {
  const { data, isFetched } = useGetMerchantByStorefrontDomainQuery(
    process.env.REACT_APP_STOREFRONT_DOMAIN!
  );

  // mock data for empty order
  // const orderData = [];

  // mock data for sample order
  const orderData = [
    {
      createdAt: "2024-10-19T00:00:00.000Z",
      id: 1,
      merchantId: 1,
      price: 1000,
      status: "PAID", // PAID, UNPAID, CANCELLED
      products: [
        {
          id: 1,
          name: "專屬網域",
          price: 799,
          count: 1,
        },
        {
          id: 2,
          name: "10GB 空間",
          price: 201,
          count: 1,
        },
      ],
      updatedAt: "2024-10-19T00:00:00.000Z",
    },
    {
      createdAt: "2024-10-19T00:00:00.000Z",
      id: 2,
      merchantId: 1,
      price: 1000,
      status: "CANCELLED", // PAID, UNPAID, CANCELLED
      products: [
        {
          id: 1,
          name: "專屬網域",
          price: 799,
          count: 1,
        },
        {
          id: 2,
          name: "10GB 空間",
          price: 201,
          count: 1,
        },
      ],
      updatedAt: "2024-10-19T00:00:00.000Z",
    },
  ];

  return (
    <div>
      {orderData.length === 0 ? (
        <>
          <div className="d-flex flex-column justify-content-center align-items-center bg-light p-3">
            <h2 className="flex-grow-1">您沒有任何訂單</h2>
            <p>您在這個商店沒有任何訂單。</p>
            <Button
              variant="primary"
              onClick={() => {
                window.location.href = "/micro-business-solutions-store";
              }}
            >
              繼續購物
            </Button>
          </div>
          <p className="text-center py-3">僅顯示2年內訂單</p>
        </>
      ) : (
        <div className="my-3">
          {orderData.map((order) => (
            <Card key={order.id} className="mb-3">
              <Card.Header>
                <p>訂單編號: No.{order.id}</p>
                <p>
                  付款狀態:{" "}
                  <span
                    className={`text-${
                      order.status === "PAID"
                        ? "success"
                        : order.status === "CANCELLED"
                        ? "danger"
                        : "warning"
                    }`}
                  >
                    {order.status === "PAID"
                      ? "已付款"
                      : order.status === "CANCELLED"
                      ? "已取消"
                      : "未付款"}
                  </span>
                </p>
              </Card.Header>
              <Card.Body>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>商品名稱</th>
                      <th>數量</th>
                      <th>價格</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.products.map((product) => (
                      <tr key={product.id}>
                        <td>{product.name}</td>
                        <td>{product.count}</td>
                        <td>NT${product.price.toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <p>訂單總價: NT${order.price.toLocaleString()}</p>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">
                  訂單建立日期: {new Date(order.createdAt).toLocaleDateString()}
                </small>
              </Card.Footer>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
}
