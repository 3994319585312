import { useGetMerchantByStorefrontDomainQuery } from "../hooks/Merchant";
import { useEffect, useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faPerson,
  faBoxesPacking,
  faClose,
} from "@fortawesome/free-solid-svg-icons";

export function MemberDashboard() {
  const { data, isFetched } = useGetMerchantByStorefrontDomainQuery(
    process.env.REACT_APP_STOREFRONT_DOMAIN!
  );
  // display save success alert
  const [isDisplaySaveSuccess, setIsDisplaySaveSuccess] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("資料已儲存");

  // mock data for customer
  const initialCustomerData = {
    // 會員資料
    username: "王小明",
    email: "",
    phone: "0912345678",
    // 訂閱
    isSubscribeEmail: true,
    isSubscribeSMS: false,
    // 送貨與付款資料
    contactPhone: "0912345678",
    deliveryAddress: "台北市信義區",
  };

  // State to handle form data
  const [customerData, setCustomerData] = useState(initialCustomerData);

  // Handle form input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setCustomerData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisplayMessage("資料已儲存");
    setIsDisplaySaveSuccess(true);
    // Here you would typically handle the data submission logic (e.g., send data to the server)
    console.log("Submitted Data:", customerData);
  };

  // handle reset password
  const handleResetPassword = () => {
    setDisplayMessage("重設密碼信件已寄出");
    setIsDisplaySaveSuccess(true);
    alert("重設密碼");
  };

  return (
    <div className="my-3">
      <Form onSubmit={handleSubmit}>
        <Row>
          {/* Left hand side: 會員資料 */}
          <Col md={6}>
            {/* 會員資料 */}
            <Card className="mb-3">
              <Card.Header
                className="h6"
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                {/* 儲存成功提示 綠色區塊 */}
                {isDisplaySaveSuccess && (
                  <div
                    className="alert alert-success border-0 text-center py-4 rounded-0"
                    role="alert"
                  >
                    {displayMessage}
                    {/* close button */}
                    <Button
                      variant="link"
                      className="p-0"
                      style={{
                        position: "absolute",
                        top: "12px",
                        right: "10px",
                        color: "rgba(0,0,0,.2)",
                        fontSize: "1.5rem",
                      }}
                      onClick={() => setIsDisplaySaveSuccess(false)}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </Button>
                  </div>
                )}
                {/* 標題 */}
                <FontAwesomeIcon
                  icon={faPerson}
                  style={{ color: "rgba(0,0,0,.2)" }}
                />
                <strong className="px-2">會員資料</strong>
              </Card.Header>
              <Card.Body>
                {/* Username Field */}
                <Form.Group as={Row} controlId="username">
                  <Form.Label column md={4}>
                    姓名
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      name="username"
                      value={customerData.username}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                {/* Phone Field */}
                <Form.Group as={Row} controlId="phone" className="mt-3">
                  <Form.Label column md={4}>
                    手機
                  </Form.Label>
                  <Col md={8}>
                    <div className="input-group">
                      <span className="input-group-text">+886</span>
                      <Form.Control
                        type="text"
                        name="phone"
                        value={customerData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                  </Col>
                </Form.Group>

                {/* Email Field */}
                <Form.Group as={Row} controlId="email" className="mt-3">
                  <Form.Label column md={4}>
                    電子郵件<span className="text-danger">*</span>
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      required
                      type="email"
                      name="email"
                      value={customerData.email}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>

                {/* Reset Password Button */}
                <Form.Group as={Row} controlId="resetPassword" className="mt-3">
                  <Form.Label column md={4}>
                    密碼
                  </Form.Label>
                  <Col md={8}>
                    <Button
                      variant="link"
                      className="px-0"
                      onClick={handleResetPassword}
                    >
                      重設新的密碼
                    </Button>
                  </Col>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>

          {/* Right hand side: 訂閱 and 送貨與付款資料 */}
          <Col md={6}>
            {/* 訂閱狀態 */}
            <Card className="mb-3">
              <Card.Header
                className="h6"
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <FontAwesomeIcon
                  icon={faBell}
                  style={{ color: "rgba(0,0,0,.2)" }}
                />
                <strong className="px-2">訂閱狀態</strong>
              </Card.Header>
              <Card.Body>
                <Form.Check
                  type="switch"
                  id="subscribeEmail"
                  label="訂閱電子郵件通知"
                  name="isSubscribeEmail"
                  checked={customerData.isSubscribeEmail}
                  onChange={handleInputChange}
                />
                <Form.Check
                  type="switch"
                  id="subscribeSMS"
                  label="訂閱簡訊通知"
                  name="isSubscribeSMS"
                  checked={customerData.isSubscribeSMS}
                  onChange={handleInputChange}
                />
              </Card.Body>
            </Card>

            {/* 送貨與付款資料 */}
            <Card>
              <Card.Header
                className="h6"
                style={{ backgroundColor: "transparent", border: "none" }}
              >
                <FontAwesomeIcon
                  icon={faBoxesPacking}
                  style={{ color: "rgba(0,0,0,.2)" }}
                />
                <strong className="px-2">送貨與付款資料</strong>
              </Card.Header>
              <Card.Body>
                {/* Contact Phone Field */}
                <Form.Group as={Row} controlId="contactPhone">
                  <Form.Label column md={4}>
                    聯絡電話(選填)
                  </Form.Label>
                  <Col md={8}>
                    <div className="input-group">
                      <span className="input-group-text">+886</span>
                      <Form.Control
                        type="text"
                        name="contactPhone"
                        value={customerData.contactPhone}
                        onChange={handleInputChange}
                      />
                    </div>
                  </Col>
                </Form.Group>

                {/* Delivery Address Field */}
                <Form.Group
                  as={Row}
                  controlId="deliveryAddress"
                  className="mt-3"
                >
                  <Form.Label column md={4}>
                    送貨地址(選填)
                  </Form.Label>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      name="deliveryAddress"
                      value={customerData.deliveryAddress}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Cancel Button */}
        <div className="d-flex justify-content-end gap-3">
          <div className="text-center mt-4">
            <Button variant="outline-secondary" type="button">
              取消
            </Button>
          </div>
          {/* Submit Button */}
          <div className="text-center mt-4">
            <Button variant="primary" type="submit">
              儲存變更
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}
