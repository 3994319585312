import React from 'react';
import '../styles/BannerSectionDark.css'; 

const BannerSectionDark = ({ category, title, subtitle, backgroundImage }) => {
  return (
    <section 
      className="banner-section-dark" 
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="container">
        <h5 className="banner-category">{category}</h5>
        <h1 className="banner-title">{title}</h1>
        <p className="banner-subtitle">{subtitle}</p>
      </div>
    </section>
  );
};

export default BannerSectionDark;
