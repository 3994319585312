import React from 'react';
import NewsDetail from '../components/NewsDetail';
import ArticleImage from '../assets/article-image2.png';

const ArticleTwo = () => {
  const content = [
    "Big-O 無牆開店e秒通持續增加更多元的註冊方式，為便利更多客戶使用我們的系統，目前該系統新增以下功能：",
    [
      "會員登入/註冊",
      "新增可直接透過LINE綁定直接註冊，會員可以不需輸入其他相關資訊，即可開始購物。",
      "新增可直接透過Google綁定直接註冊，會員可以不需輸入其他資訊，直接透過Google E-mail進行綁定，立即開始消費！"
    ]
  ];

  return (
    <NewsDetail
      title="無牆開店e 秒通產品新增會員註冊可採用第三方註冊"
      author="Big-O技術編輯小組"
      date="2024-04-12"
      imageSrc={ArticleImage}
      imageAlt="Meta Certified Company Award"
      content={content}
    />
  );
};

export default ArticleTwo;
