import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/LeadingBrand.css'; 
import LeadingBrandBanner from '../assets/LeadingBrandBanner.png'; 
import Logo1 from '../assets/logo1.png'; 
import Logo2 from '../assets/logo2.png'; 
import Logo3 from '../assets/logo3.png'; 
import Logo4 from '../assets/logo4.png'; 
import Logo5 from '../assets/logo5.png'; 
import Logo6 from '../assets/logo6.png'; 

const LeadingBrand = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const cases = [
    { image: Logo1, title: '小真服飾坊', link: 'https://fourth-studio.com/' },
    { image: Logo2, title: '日亭商店', link: 'https://ritingstore.com/' },
    { image: Logo3, title: '葛瑞絲商旅', link: 'https://grace-hostel.com/' },
    { image: Logo4, title: '巧薇髮訊美容材料行', link: 'https://gutssalon.com/' },
    { image: Logo5, title: '仟殿美容院', link: 'https://goalsalon.com/' },
    { image: Logo6, title: '府廣有限公司', link: 'https://fuguangtoys.com/' },
  ];

  return (
    <section className="LeadingBrand">
      <Helmet>
        <title>精選客戶案例 - 品牌成功故事 | 我們的合作夥伴</title>
        <meta name="description" content="探索我們的精選客戶案例，了解如何通過品牌電商和數位轉型幫助微型商家實現成功。" />
        <meta name="keywords" content="精選客戶案例, 品牌電商, 數位轉型, 微型商家, 合作夥伴, 成功案例" />
      </Helmet>
      <div className="topest-section" style={{ backgroundImage: `url(${LeadingBrandBanner})` }}>
        <div className="container text-center">
          <h1 className="mb-3">精選客戶案例</h1>
          <p>與微型商家品牌合作，落實數位轉型在地化，打造專屬品牌電商So Fast, So Easy!!</p>
        </div>
      </div>
      <div className="container brands-section p-4">
        <div className="row gx-5">
          {cases.map((item, index) => (
            <div className="col-md-4 col-sm-6 mb-4" key={index}>
              <div className="card h-100 text-center">
                <img 
                  src={item.image} 
                  alt={`${item.title} logo`} 
                  className="card-img-top mx-auto rounded-image" 
                 
                />
                <div className="card-body">
                  <h5 className="card-title">{item.title}</h5>
                </div>
                <div className="card-footer bg-white">
                  <a href={item.link} target="_blank" rel="noopener noreferrer" className= "custom-button-bottom">前往官網 →</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LeadingBrand;
