import React from 'react';
import '../styles/CallToAction.css'; 

const CallToAction = () => {
  return (
    <section className="CallToAction py-5">
      <div className="container text-center">
        <h2 className="lead">立即開始實現數位轉型之旅！與 Big-O 合作創造未來！</h2>
        <div className="btn-group mt-4" role="group">
          <a href='/contact' className="btn btn-light btn-lg rounded-pill">請專人與我聯繫</a>
          <span className="mx-3 align-self-center">或</span>
          <a href='/contact'  className="btn btn-light btn-lg rounded-pill">立即線上註冊</a>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
