import React from 'react';
import '../styles/CallToActioTwo.css'; 

const CallToActionTwo = ({ title, subtitle, buttonText, buttonLink }) => {
  return (
    <section className="CallToAction text-center py-5">
      <div className="container">
        <h2>{title}</h2>
        <p dangerouslySetInnerHTML={{ __html: subtitle }}></p>
        <div className="btn-group">
          <a href={buttonLink} className="btn btn-light btn-lg rounded-pill">{buttonText}</a>
        </div>
      </div>
    </section>
  );
};

export default CallToActionTwo;
