import React from 'react';
import '../styles/Contact.css';
import icon1 from '../assets/contact/Icon.svg';  
import icon2 from '../assets/contact/Icon2.svg'; 
import emailIcon from '../assets/contact/email.svg';
import phoneIcon from '../assets/contact/phone.svg';
import messageplussquare from '../assets/contact/message-plus-square.svg';

const CardRow = () => {
  const cards = [
    {
      icon: icon1,
      title: '了解Big-O方案',
      description: '想取得Big-O的解決方案嗎？了解如何透過雲端及AI技術完成您的數位轉型。',
      email: 'service@boiic.org',
      phone: '02-8979-1656',
      justify: 'end'
    },
    {
      icon: icon2,
      title: '聯絡Big-O客服',
      description: '詢問方案，及系統佈建等整體建置。',
      email: 'service@boiic.org',
      phone: '02-8979-1656',
      justify: 'start'
    }
  ];

  return (
    <div className="container contact">
      <div className="row justify-content-top g-4 mb-4">
        {cards.map((card, i) => (
          <div className={`col-md-6 d-flex justify-content-${card.justify}`} key={i}>
            <div className="card card-square">
              <div className="card-body d-flex align-items-start">
                <div className='d-flex align-items-center'>
                  <div className="icon-container me-3">
                    <img src={card.icon} alt={card.title} className="img-fluid"  />
                  </div>
                  <h5 className="card-title">{card.title}</h5>
                </div>
                <div className="text-container">
                  <p className="card-text mt-3">{card.description}</p>

                  {card.phone && (
                    <p className="card-text">
                      <small>
                        <img src={phoneIcon} alt="Phone Icon" className="icon-svg me-2" />
                        {card.phone}
                      </small>
                    </p>
                  )}

                  {card.email && (
                    <p className="card-text">
                      <small>
                        <img src={emailIcon} alt="Email Icon" className="icon-svg me-2" />
                        <a href={`mailto:${card.email}`}>{card.email}</a>
                      </small>
                    </p>
                  )}

                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardRow;
