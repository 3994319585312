import BannerSection from "../components/BannerSection";
import { useEffect, useState } from "react";
import bannerImage from "../assets/Checkout-banner2.jpg";

interface CartItem {
  name: string;
  price: number;
  count: number;
  imageUrl: string;
}

const Cart = () => {
  const [items, setItems] = useState<CartItem[]>([
    {
      name: "自選版型-starlight",
      price: 5600,
      count: 1,
      imageUrl:
        "https://big-o-prod.s3.ap-northeast-1.amazonaws.com/merchant/1/media/4",
    },
    {
      name: "專屬獨有網域",
      price: 1050,
      count: 1,
      imageUrl:
        "https://big-o-prod.s3.ap-northeast-1.amazonaws.com/merchant/1/media/5",
    },
  ]);

  return (
    <>
      <BannerSection
        category=""
        title="購物車"
        subtitle=""
        backgroundImage={bannerImage}
        titleStyle={{ textAlign: "center", width: "100%" }}
      />
      <section className="py-5">
        <div className="container d-md-flex">
          {/* product list */}
          <div className="flex-grow-1">
            <h4
              style={{
                width: "fit-content",
                paddingRight: 10,
                position: "relative",
                fontWeight: 400,
                zIndex: 2,
              }}
            >
              商品清單
            </h4>
            {items.map((item, index) => (
              <div
                key={index}
                className="d-flex align-items-center justify-content-between"
                style={{
                  marginTop: 15,
                  padding: 15,
                  backgroundColor: "#f7f5fa",
                  borderRadius: 5,
                }}
              >
                <div className="d-flex align-items-center" style={{ flex: 2 }}>
                  <img
                    className="d-none d-md-block"
                    src={item.imageUrl}
                    alt="product"
                    style={{
                      width: 100,
                      height: 100,
                      objectFit: "cover",
                      borderRadius: 5,
                    }}
                  />
                  <div className="ms-3">
                    <h5 style={{ fontWeight: 400, fontSize: 16 }}>
                      {item.name}
                    </h5>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      NT$ {item.price.toLocaleString()}
                    </span>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: 14,
                      cursor: "pointer",
                      color: "gray",
                    }}
                    onClick={() => {
                      const newItems = [...items];
                      newItems[index].count -= 1;
                      setItems(newItems);
                      if (newItems[index].count === 0) {
                        newItems.splice(index, 1);
                        setItems(newItems);
                      }
                    }}
                  >
                    <i className="fas fa-minus"></i>
                  </span>
                  <span style={{ marginLeft: 5, marginRight: 5 }}>
                    {item.count}
                  </span>
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: 14,
                      cursor: "pointer",
                      color: "gray",
                    }}
                    onClick={() => {
                      const newItems = [...items];
                      newItems[index].count += 1;
                      setItems(newItems);
                    }}
                  >
                    <i className="fas fa-plus"></i>
                  </span>
                </div>
                <div style={{ flex: 1 }}>
                  <span style={{ marginLeft: 15 }}>
                    NT$ {(item.price * item.count).toLocaleString()}
                  </span>
                </div>
                <div>
                  <span
                    style={{ marginLeft: 15, cursor: "pointer" }}
                    onClick={() => {
                      const newItems = [...items];
                      newItems.splice(index, 1);
                      setItems(newItems);
                    }}
                  >
                    <i className="fas fa-trash"></i>
                  </span>
                </div>
              </div>
            ))}
            <div
              style={{
                cursor: "pointer",
                marginTop: 15,
                padding: 15,
                backgroundColor: "#333",
                textAlign: "center",
                color: "#fff",
              }}
              onClick={() => {
                window.location.href = "/checkout";
              }}
            >
              訂單結帳
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
