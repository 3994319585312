import React, { useEffect } from 'react';
import '../styles/TermsAndConditions.css'; 
import PureBannerSection from '../components/PureBannerSection';

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = (
    <>
      <section>
        <p>
          歡迎您加入Big-O，並選用大鷗摩飛智能有限公司(以下簡稱本公司)的數位轉型雲端服務解決方案(統稱Big-O 解決方案)，本服務條款是使用本服務的基本原則，與您的權利義務至關重要，請務必詳閱以下各方案之對應服務條款。
        </p>
        <ol>
          <li>
            請您依照您經營的事業所在地區或您欲與本公司結算的方式，遵守各方案對應的會員暨系統維運服務條款，該方案之會員暨系統維運服務條款有部分內容及服務之提供者可能會因地區、準據法律、幣別或當地習慣而有所不同；請您詳閱您所申請之解決方案對應之會員暨系統維運服務條款以及您收到的發票用以確認其資訊。
          </li>
          <li>
            本公司乃依據Big-O 解決方案研擬專屬各方案之Big-O 會員暨系統維運服務條款，並提供以下「微型商務」、「教育自媒體」、「整合式醫療服務」、「智慧建築與設計應用」以及「金融實戰應用」之解決方案予您使用，以及輔助您銷售、規劃及數位轉型落地之相關服務。
          </li>
          <li>
            您同意以電子文件作為表示方法，依據本服務條款交換之電子文件，其效力與書面文件相同。請您留意，當註冊為本公司之會員用戶時，即視為您已經充分了解、閱讀並同意本公司各方案之服務條款，且承諾遵守所有服務條款之一切內容。
          </li>
          <li>
            您得隨時於Big-O 官網查閱各服務方案之最新版本，若版本更新時，將於本公司官網公告；若對Big-O 服務或所有服務條款內容有疑問，請務必通知我們，留下您的資訊，我們將為您說明或協助您終止帳戶，但自版本更新日起算14日內未有相關意見者，視為您同意接受該次更新的版本。
          </li>
          <li>
            Big-O 服務是以SaaS雲端技術與AI技術為基礎，提供各式軟體服務，並協助各產業領域數位轉型落地及操作便利之雲端解決方案，各方案所具備之功能或自主搭配之選擇功能，其具體內容請參照Big-O 官網核心產品之各解決方案，並以依據簽署之合約方案功能明細為準。
          </li>
        </ol>
      </section>
    </>
  );
  



  return (
    <>
      <PureBannerSection
        title="Big-O會員暨系統維運服務條款"
      />
      <section className="py-5">
      <div className="terms-content-container container">
        <div className="terms-content">
          {content}
        </div>

        <div className="footer-info">
          <p className="updated-date">最後更新：2024-07-29</p>
          <p className="copyright">© 2024 Big-O Information Intelligence Corp.</p>
          <div className="important-note">
            <p>
            【注意事項】請特別注意，在您繳納系統維運服務費後所收到之發票主體，若非為本條款所涉之主體，請向單據上之主體再行確認。
           
            </p>
          </div>
          <div className="solution-links mt-5">
            <p><a href="/micro-business-solutions-feature">方案一：微型商務解決方案</a></p>
            <p><a href="#">方案二：教育自媒體解決方案</a></p>
            <p><a href="#">方案三：整合式醫療服務解決方案</a></p>
            <p><a href="#">方案四：智慧建築與設計應用解決方案</a></p>
            <p><a href="#">方案五：金融實戰應用解決方案</a></p>
          </div>
        </div>
      </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
