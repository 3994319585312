import React from "react";
import { Product } from "./../api/Product/Product";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Button } from "react-bootstrap";
import { IconShoppingCartPlus } from "@tabler/icons-react";

export type Props = {
  product: Product;
};

export function ProductItem({ product }: Props) {
  const { id, name, productMedias, description, slogan, price, originalPrice } =
    product;

  const handleAddToCart = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (product === undefined) {
      return;
    }
    // Add your logic for adding the product to the cart here
  };

  return (
    <Card className="h-100" style={{ borderRadius: "8px", padding: "16px" }}>
      <Card.Img
        variant="top"
        src={
          productMedias[0] && productMedias[0].media
            ? productMedias[0].media?.url
            : "https://placehold.co/300x180?text=Placeholder"
        }
        alt={name}
        style={{ height: "180px", objectFit: "cover" }}
      />
      <Card.Body className="d-flex flex-column justify-content-between p-0">
        <Card.Title className="fw-bold">{name}</Card.Title>
        <Card.Text>{slogan}</Card.Text>
        <Card.Text
          dangerouslySetInnerHTML={{ __html: description }}
        ></Card.Text>

        <div className="mt-auto">
          {/* Price Section */}
          <div className="d-flex justify-content-between align-items-end">
            <div>
              {originalPrice && (
                <p
                  className={`mb-0 ${
                    price
                      ? "text-muted text-decoration-line-through"
                      : "text-danger fw-bold"
                  }`}
                >
                  NT${originalPrice}
                </p>
              )}
              {price && (
                <p className="text-danger fw-bold fs-5 mb-0">NT${price}</p>
              )}
            </div>

            {/* Add to Cart Button */}
            {/* <Button
              variant="outline-primary d-flex align-items-center"
              onClick={handleAddToCart}
            >
              <IconShoppingCartPlus size={20} />
            </Button> */}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
